// libs
import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { ToastContainer, toast } from 'react-toastify'

import Layout from 'layouts/zh'

// components
import Seo from 'components/seo'
import PageBottomCard from 'components/PageBottomCard'
import { Languages } from 'i18n/config'
import SearchBanner from 'components/TargetDisassembly/SearchBanner'
import TagsCard, { TagProps } from 'components/TargetDisassembly/TagsCard'

// apis
import { fetchAllIndustry, searchTarget } from 'api/doc'
import { getHotTagList, getIndustryList } from 'api'

// utils
import { toastError } from 'utils/utils'

// styles
import * as styles from './index.module.less'
import 'styles/base.less'
import 'react-toastify/dist/ReactToastify.css'

const Home: React.FC = (props) => {
  return (
    <Layout {...props}>
      <HomeState />
    </Layout>
  )
}

interface HomeStateProps {
  lang?: Languages
}
export const HomeState: React.FC<HomeStateProps> = (props) => {
  const { lang } = props

  const [industries, setIndustries] = useState<Array<TagProps>>([])
  const [hotTags, setHotTags] = useState<
    Array<{
      tagName: string
      tagId: string
    }>
  >([])

  const [openState, setOpenState] = useState<Array<boolean>>([])

  useEffect(() => {
    setOpenState(new Array(industries.length).fill(false))
  }, [industries])

  useEffect(() => {
    getIndustryList()
      .then((res) => {
        setIndustries(
          res.data.map((obj) => {
            const tags = obj.documents.map((document) => {
              return {
                id: document.objId,
                name: document.name,
                desc: document.docPreview,
              }
            })
            return {
              name: obj.tagName,
              coverMobile: obj.moPic,
              coverPc: obj.pcPic,
              themeColor: obj.color,
              tags,
            }
          }),
        )
      })
      .catch((err) => {
        toastError(err.message)
      })

    getHotTagList()
      .then(({ data }) => {
        setHotTags(
          data.map((obj) => {
            return {
              tagName: obj.targetName,
              tagId: obj.targetId,
            }
          }),
        )
      })
      .catch((err) => {
        toastError(err.message)
      })
  }, [])

  return (
    <>
      <Seo
        title="指标拆解工具｜数据指标体系查询平台-神策数据"
        description="神策数据指标拆解工具能够提供各行业数据指标查询，帮助企业合理构建指标体系，从而使分析师、市场营销、产品、运营等人员能够及时通过关注各项指标进行数据分析，调整营销策略，从而为公司业务带来最大价值。"
        keywords="指标体系,数据指标,指标体系构建,运营数据指标,电商数据指标"
        saTitle="指标拆解工具"
      />
      <main>
        <SearchBanner
          className={classnames('!pt-[4rem] lg:!pt-[64px]', styles.Home)}
          onSearch={(key) => {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                if (key.length > 0) {
                  searchTarget(key, 1, 100)
                    .then((res) => {
                      const { data } = res.data
                      resolve(
                        data.map((obj) => {
                          return {
                            name: obj.name,
                            key: obj.objId,
                            cate: obj.tagName,
                          }
                        }),
                      )
                    })
                    .catch((err) => reject(err))
                } else {
                  resolve([])
                }
              }, 0)
            })
          }}
          tags={hotTags}
        />

        <div
          className="
          flex
          flex-row
          flex-wrap
          justify-start
          tagCards
          py-[20px]
          px-[25px]
          lg:px-0
          lg:max-w-[1200px]
          lg:mx-auto
          lg:py-0
          lg:mt-[60px]
          lg:mb-[100px]
          "
        >
          {industries.map((industry, i) => (
            <TagsCard
              key={i}
              className={classnames(
                `
                  tagCard
                  w-full
                  lg:w-[380px]
                  lg:mt-[20px]
                  lg:mr-[20px]
                  will-change-transform
                  transition-all
                  ease-in-out
                  duration-300
                  hover:handshake
                `,
                {
                  'lg:mr-x-0': (i + 1) % 3 === 0,
                },
              )}
              tags={industry.tags}
              coverMobile={industry.coverMobile}
              coverPc={industry.coverPc}
              themeColor={industry.themeColor}
              open={openState[i] || false}
              index={i}
              onOpen={(index) => {
                const newOpenState = openState.map((state, i) => {
                  if (i === index) {
                    return true
                  } else {
                    return false
                  }
                })
                setOpenState(newOpenState)
              }}
            />
          ))}
        </div>
      </main>

      <ToastContainer />
    </>
  )
}

export default Home
