import React, { useEffect, useState } from 'react'
import classnames from 'classnames'

import * as styles from './index.module.less'
import { navigate } from 'gatsby'

export interface TagProps {
  id: string
  name: string
  desc: string
}

interface TagsCardProps {
  coverMobile?: string
  coverPc?: string
  className?: string
  themeColor: string
  tags?: Array<TagProps>
  open: boolean
  index: number
  onOpen: (number) => void
}

const TagsCard: React.FC<TagsCardProps> = (props) => {
  const { className, coverMobile, coverPc, tags = [], themeColor, open, onOpen, index } = props

  // 63px mean paddingY(2 * 10px) + borderTop(1px) + content(42px)
  // content(42px) = h5(lineHeight: 21px) + p(lineHeight: 21)
  // 固定显示8个, 超出部分滚动显示
  const maxTotalListHeight = 8 * 63
  const [isOpen, setIsOpen] = useState<boolean>(open)
  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const inlineStyles = {
    tagsCard: classnames(`
      mb-[10px]
      pl-[5px] 
      pr-[20px]
      lg:p-0
      shadow-[0_0_20px_0_rgba(153,169,191,.2)]
      lg:shadow-[0_20px_40px_0_rgba(153,169,191,0.1)]
    `),
    tagsCardCoverPc: `
      hidden
      lg:block
    `,
    tagsCardCoverMobile: `
      flex
      justify-between
      items-center
      lg:hidden
    `,
    tagListMobile: `transition-all overflow-scroll lg:hidden`,
  }

  const cnames = classnames(styles.TagsCard, inlineStyles.tagsCard, className)
  return (
    <div className={cnames}>
      <div
        className={inlineStyles.tagsCardCoverMobile}
        onClick={(e) => {
          onOpen && onOpen(index)
          setIsOpen(!isOpen)
        }}
      >
        <div className="pb-[13px] pt-[5px]">
          <img src={coverMobile} alt="" className="h-[42px] w-auto" />
        </div>
        <span className="icon inline-block">
          <img
            style={{
              transform: isOpen ? 'rotate(0)' : 'rotate(-90deg)',
            }}
            className={classnames(
              {
                '-rotate-90': !isOpen,
                'rotate-0': isOpen,
              },
              'transition-all',
            )}
            src={require('assets/images/target_disassembly/caret-bottom.svg').default}
            alt=""
          />
        </span>
      </div>

      {/* pc */}
      <div className={inlineStyles.tagsCardCoverPc}>
        <img src={coverPc} alt="" className="h-[100px]" />

        <div className="cardContent mt-[20px] mx-[16px]">
          <ul
            className={`
            h-[160px]
            w-auto
            overflow-hidden
          `}
          >
            {tags.map((tag, index) => (
              <li
                key={index}
                className={`
                  cursor-pointer
                  inline-block
                  text-[14px]
                  leading-[1]
                  text-sd-font-p
                  px-[14px]
                  py-[5px]
                  mr-[8px]
                  mb-[14px]
                  border-[1px]
                  transition-colors
                  border-sd-grey
                  border-dotted
                  rounded-[13px_0_13px_13px]
                `}
                onMouseEnter={(e) => {
                  e.currentTarget.style.color = themeColor
                  e.currentTarget.style.borderColor = themeColor
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.color = '#475669'
                  e.currentTarget.style.borderColor = '#C8D2E0'
                }}
                onClick={(e) => {
                  navigate(`/target_disassembly/target.html?id=${encodeURIComponent(tag.id)}`)
                }}
              >
                {tag.name}
              </li>
            ))}
          </ul>

          <div className="text-center mb-[10px]">
            <a
              onClick={(e) => {
                if (tags[0]) {
                  navigate(`/target_disassembly/target.html?id=${encodeURIComponent(tags[0].id)}`)
                }
              }}
              className="more text-sd-font-input hover:text-primary text-[14px] leading-[2] cursor-pointer"
              onMouseEnter={(e) => {
                e.currentTarget.style.color = themeColor
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.color = '#99a9bf'
              }}
            >
              更多...
            </a>
          </div>
        </div>
      </div>

      {/* mobile */}
      <ul
        className={classnames(inlineStyles.tagListMobile)}
        style={{
          maxHeight: isOpen ? `${maxTotalListHeight}px` : '0px',
          opacity: isOpen ? '1' : '0',
          marginTop: isOpen ? '10px' : '0',
        }}
      >
        {tags.map((tag, index) => (
          <li
            onClick={(e) => {
              navigate(`/target_disassembly/target.html?id=${encodeURIComponent(tag.id)}`)
            }}
            key={index}
            className={classnames(`
              flex
              justify-between
              items-center
              py-[10px] 
              ml-[15px]
              border-t-[1px]
              border-solid
              border-sd-tags-border
            `)}
          >
            <div
              className={`
              `}
            >
              <h5
                className={`
                  text-[14px]
                  text-sd-black
                  line-clamp-1
                `}
              >
                {tag.name}
              </h5>
              <p
                className={`
                  text-[14px]
                  leading-normal
                  text-sd-font-input
                  line-clamp-1
                `}
              >
                {tag.desc}
              </p>
            </div>

            <img className="ml-[10px]" src={require('assets/images/icons/icon-angle-right.svg').default} alt="" />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default TagsCard
